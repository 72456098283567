import React from "react";
import { Row, Col } from "react-bootstrap";
import { useSelector } from "react-redux";

const MainFooter = () => {
  let today = new Date();
  let year = today.getFullYear();

  return (
    <Col className="main-footer">
      <div className="footer-logout">
        <Row lg="12" className="footer-box-wrapper">
          <Col lg="6" className="footer-box-left">
            <img src={require('../assets/pictures/evenos-logo.png')} alt='Evenos Logo'></img>
            <p>Evenos Sdn. Bhd. (1446815-U)</p>
            <p></p>
            <p>Address : No 15, Lorong Perkasa Jaya, <br></br>
              Taman Perkasa Jaya, 13000 Butterworth, <br></br>
              Pulau Pinang, Malaysia.</p>
          </Col>
          <Col lg="6" className="footer-box-right">
            <div className="logo-wrapper">
              <div className="contact-item">

                <img
                  src={require("../assets/pictures/Touch&Go.png")}
                  alt="WhatsApp"
                ></img>
              </div>
              <div className="contact-item">

                <img
                  src={require("../assets/pictures/Visa.png")}
                  alt="Instagram"
                ></img>
              </div>
              <div className="contact-item">

                <img
                  src={require("../assets/pictures/MasterCard.png")}
                  alt="Facebook"
                ></img>
              </div>


              <div className="contact-item">

                <img
                  src={require("../assets/pictures/FPX.png")}
                  alt="WhatsApp"
                ></img>
              </div>

              <div className="contact-item">

                <img
                  src={require("../assets/pictures/ShopeePay.png")}
                  alt="WhatsApp"
                ></img>
              </div>
              <div className="contact-item">

                <img
                  src={require("../assets/pictures/GrabPay.png")}
                  alt="WhatsApp"
                ></img>
              </div>
              <div className="contact-item">

                <img
                  src={require("../assets/pictures/Boost.png")}
                  alt="WhatsApp"
                ></img>
              </div>
              <div className="contact-item">

                <img
                  src={require("../assets/pictures/MAE.png")}
                  alt="WhatsApp"
                ></img>
              </div>
            </div>

            <p>Customer Support Center : +6010-831 8158</p>
            <p>Email : evenos.marketing@gmail.com</p>
            <a href="/termsandconditions">Terms and Conditions</a>

            <p style={{ marginTop: '3px' }}>Copyright © {year}</p>
          </Col>
        </Row>
      </div>{" "}
    </Col>
  );
};

export default MainFooter;
